import { styled } from "styles/stitches.config";
import { rem } from "polished";

const Button = styled("button", {
  all: "unset",

  boxSizing: "border-box",
  padding: "13px 20px",
  maxHeight: 50,

  textAlign: "center",
  fontFamily: "$raleway",
  fontSize: rem(11),
  fontWeight: "bold",
  lineHeight: "120%",
  letterSpacing: "1.2px",
  textTransform: "uppercase",
  border: "2px solid transparent",
  cursor: "pointer",
  "&:disabled": {
    opacity: 0.3,
    cursor: 'not-allowed'
  },

  variants: {
    shape: {
      square: {
        borderRadius: 8,
      },
      rounded: {
        borderRadius: 32,
      },
    },
    color: {
      inverse: {
        background: "$white",
        color: "$emerald500",
        "&:hover": {
          background: "$grey200",
        },
      },
      primary: {
        background: "$emerald500",
        color: "white",
        "&:hover": {
          backgroundColor: "$emerald600",
        },
      },
      secondary: {
        background: "transparent",
        color: "$emerald500",
        borderColor: "$emerald500",
      },
      transparent: {
        background: "transparent",
        color: "$emerald500",
        "&:hover": {
          color: "$emerald600",
        },
      },
    },
  },

  defaultVariants: {
    shape: "square",
    color: "primary",
  },
});

// const Button = ({
//   color = "primary",
//   shape = "square",
//   ...props
// }: ButtonProps) => {
//   return (
//     <S.Container {...props} color={color} shap={shape}>
//       {props.children}
//     </S.Container>
//   );
// };

export default Button;
