import Link from 'next/link'
import { HamburgerIcon, PhoneIcon } from '@chakra-ui/icons'

import LogoCG from 'public/images/logo-central-da-grama.svg'
import FloatButton from 'components/FloatButton'

import * as S from './styles'
import { useState } from 'react'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <S.Container open={isOpen}>
      <S.LogoWrap>
        <Link href="/" passHref>
          <S.LogoContent>
            <S.LogoImage
              src={LogoCG}
              alt="logo central da grama"
              title="logotipo central da grama"
              width={70}
              height={60}
              layout="fixed"
              priority
            />
            <S.LogoText title="central da grama">central da grama</S.LogoText>
          </S.LogoContent>
        </Link>
      </S.LogoWrap>
      <S.Navbar open={isOpen}>
        <Link href="/" passHref>
          <S.Navbar_Link title="início central da grama" alt="início">
            Início
          </S.Navbar_Link>
        </Link>
        <Link href="/comprar-grama" passHref>
          <S.Navbar_Link title="comprar grama" alt="comprar grama">
            Orçamento
          </S.Navbar_Link>
        </Link>
        <Link href="/dicas" passHref>
          <S.Navbar_Link title="dicas de grama" alt="dicas">
            Dicas
          </S.Navbar_Link>
        </Link>
        <Link href="/fotos" passHref>
          <S.Navbar_Link title="fotos de grama" alt="fotos">
            Fotos
          </S.Navbar_Link>
        </Link>
        <Link href="/gramas" passHref>
          <S.Navbar_Link title="tipos de gramas" alt="gramas">
            Gramas
          </S.Navbar_Link>
        </Link>
        <Link href="/contato" passHref>
          <S.Navbar_Link title="cotar grama" alt="Contato">
            Contato
          </S.Navbar_Link>
        </Link>
      </S.Navbar>
      <S.Button shape="rounded">
        <a
          href="https://api.whatsapp.com/send/?phone=5511996172172&type=phone_number&app_absent=0"
          target="_blank"
          without="true"
          rel="noreferrer"
          title="ver preço de grama pelo whatsapp"
        >
          <PhoneIcon />
          whatsapp
        </a>
      </S.Button>
      <S.ButtonMenu
        onClick={() => {
          setIsOpen((value) => !value)
        }}
        variant="outline"
        aria-label="abrir menu"
        icon={<HamburgerIcon />}
      />
      <FloatButton />
    </S.Container>
  )
}

export default Navbar
