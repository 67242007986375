import { SimpleGrid } from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import Link from 'next/link'

import LogoCG from 'public/images/logo-central-da-grama.png'

import * as S from './styles'
import { useRouter } from 'next/router'

const Footer = () => {
  const router = useRouter()
  const dataAtual = new Date()
  const anoAtual = dataAtual.getFullYear()

  return (
    <S.Footer>
      <SimpleGrid columns={[1, null, 2]}>
        <S.Left>
          <S.Content>
            <S.Top>
              <S.LogoWrap>
                <S.Logo
                  src={LogoCG}
                  alt="logo central da grama"
                  width={30}
                  height={30}
                  layout="fixed"
                  objectFit="cover"
                />
                <S.LogoText>CG</S.LogoText>
              </S.LogoWrap>
              <S.Socials>
                <Link href="https://www.instagram.com/centraldagrama/" passHref>
                  <S.Social
                    className="instagram"
                    target="_blank"
                    rel="noreferrer"
                    title="Siga-nos no Instagram!"
                  >
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.00201 4.48464C6.44808 4.48464 4.38806 6.54511 4.38806 9.09961C4.38806 11.6541 6.44808 13.7146 9.00201 13.7146C11.5559 13.7146 13.616 11.6541 13.616 9.09961C13.616 6.54511 11.5559 4.48464 9.00201 4.48464ZM9.00201 12.0999C7.35159 12.0999 6.00234 10.7544 6.00234 9.09961C6.00234 7.44481 7.34757 6.09927 9.00201 6.09927C10.6564 6.09927 12.0017 7.44481 12.0017 9.09961C12.0017 10.7544 10.6524 12.0999 9.00201 12.0999ZM14.8809 4.29586C14.8809 4.89432 14.399 5.37229 13.8047 5.37229C13.2064 5.37229 12.7285 4.8903 12.7285 4.29586C12.7285 3.70142 13.2104 3.21943 13.8047 3.21943C14.399 3.21943 14.8809 3.70142 14.8809 4.29586ZM17.9368 5.38835C17.8685 3.94642 17.5392 2.66917 16.4831 1.61685C15.431 0.564521 14.154 0.235167 12.7124 0.162869C11.2267 0.0785227 6.77334 0.0785227 5.28756 0.162869C3.84997 0.23115 2.57301 0.560504 1.5169 1.61283C0.460792 2.66516 0.135527 3.94241 0.063246 5.38434C-0.021082 6.87044 -0.021082 11.3248 0.063246 12.8109C0.131511 14.2528 0.460792 15.53 1.5169 16.5824C2.57301 17.6347 3.84596 17.9641 5.28756 18.0363C6.77334 18.1207 11.2267 18.1207 12.7124 18.0363C14.154 17.9681 15.431 17.6387 16.4831 16.5824C17.5352 15.53 17.8645 14.2528 17.9368 12.8109C18.0211 11.3248 18.0211 6.87446 17.9368 5.38835ZM16.0173 14.4054C15.7041 15.1927 15.0977 15.7992 14.3066 16.1165C13.122 16.5864 10.3111 16.4779 9.00201 16.4779C7.69292 16.4779 4.87797 16.5824 3.69738 16.1165C2.91032 15.8032 2.30396 15.1967 1.98673 14.4054C1.5169 13.2206 1.62532 10.409 1.62532 9.09961C1.62532 7.79023 1.52091 4.97465 1.98673 3.7938C2.29994 3.00656 2.9063 2.40007 3.69738 2.08276C4.88199 1.61283 7.69292 1.72128 9.00201 1.72128C10.3111 1.72128 13.126 1.61685 14.3066 2.08276C15.0937 2.39605 15.7001 3.00254 16.0173 3.7938C16.4871 4.97867 16.3787 7.79023 16.3787 9.09961C16.3787 10.409 16.4871 13.2246 16.0173 14.4054Z"
                        fill="white"
                      />
                    </svg>
                  </S.Social>
                </Link>
                <Link href="https://g.page/centraldagrama" passHref>
                  <S.Social
                    className="google"
                    target="_blank"
                    rel="noreferrer"
                    title="Conheça nossa empresa, faça uma visita!"
                  >
                    <svg
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.9354 5.27989C10.9891 5.569 11.0275 5.8581 11.0275 6.23312C11.0275 9.507 8.87106 11.8276 5.625 11.8276C2.51707 11.8276 0 9.26479 0 6.10032C0 2.93585 2.51707 0.373047 5.625 0.373047C7.14445 0.373047 8.41063 0.935602 9.3929 1.87323L7.86577 3.36561C7.45137 2.9593 6.72237 2.48268 5.625 2.48268C3.70652 2.48268 2.14102 4.10007 2.14102 6.10032C2.14102 8.10057 3.70652 9.71796 5.625 9.71796C7.85042 9.71796 8.6869 8.08494 8.81733 7.24888H5.625V5.27986H10.9354V5.27989ZM16.3672 5.4719V3.80941H14.7265V5.4719H13.0937V7.14238H14.7265V8.80487H16.3672V7.14238H18V5.4719H16.3672Z"
                        fill="white"
                      />
                    </svg>
                  </S.Social>
                </Link>
                <Link href="https://www.facebook.com/centraldagrama" passHref>
                  <S.Social
                    className="facebook"
                    target="_blank"
                    rel="noreferrer"
                    title="Curta nossa fanpage no Facebook!"
                  >
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.97092 0.964871C7.97092 0.86913 7.93289 0.77731 7.86519 0.709611C7.79749 0.641911 7.70567 0.603878 7.60993 0.603878H5.80496C4.89607 0.558603 4.00621 0.874905 3.3298 1.48367C2.65339 2.09244 2.24542 2.94419 2.19504 3.85281V5.80217H0.390076C0.294335 5.80217 0.202515 5.8402 0.134816 5.9079C0.0671164 5.9756 0.0290833 6.06742 0.0290833 6.16316V8.04032C0.0290833 8.13607 0.0671164 8.22788 0.134816 8.29558C0.202515 8.36328 0.294335 8.40132 0.390076 8.40132H2.19504V13.2386C2.19504 13.3344 2.23307 13.4262 2.30077 13.4939C2.36847 13.5616 2.46029 13.5996 2.55603 13.5996H4.72199C4.81773 13.5996 4.90955 13.5616 4.97725 13.4939C5.04495 13.4262 5.08298 13.3344 5.08298 13.2386V8.40132H6.97458C7.05486 8.40247 7.13323 8.37683 7.1973 8.32845C7.26137 8.28007 7.30749 8.21171 7.32835 8.13418L7.84818 6.25702C7.86254 6.20368 7.86447 6.14776 7.85382 6.09356C7.84317 6.03935 7.82023 5.98832 7.78675 5.94438C7.75327 5.90044 7.71016 5.86477 7.66073 5.84012C7.6113 5.81546 7.55687 5.80248 7.50163 5.80217H5.08298V3.85281C5.10094 3.6741 5.18486 3.5085 5.31836 3.38835C5.45186 3.2682 5.62536 3.20212 5.80496 3.20302H7.60993C7.70567 3.20302 7.79749 3.16499 7.86519 3.09729C7.93289 3.02959 7.97092 2.93777 7.97092 2.84203V0.964871Z"
                        fill="white"
                      />
                    </svg>
                  </S.Social>
                </Link>
                <Link href="https://twitter.com/centraldagrama" passHref>
                  <S.Social
                    className="twitter"
                    target="_blank"
                    rel="noreferrer"
                    title="Siga-nos no Twitter!"
                  >
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.9957 2.14165C15.4076 2.40223 14.7759 2.5783 14.1118 2.65789C14.797 2.24785 15.3097 1.6025 15.5541 0.842247C14.9103 1.22466 14.2057 1.49385 13.4709 1.63809C12.9767 1.11048 12.3222 0.76078 11.609 0.643269C10.8957 0.525759 10.1636 0.647014 9.52634 0.988212C8.88907 1.32941 8.38227 1.87146 8.08462 2.5302C7.78697 3.18895 7.71514 3.92753 7.88027 4.63128C6.57572 4.56578 5.29952 4.22671 4.13449 3.63607C2.96947 3.04542 1.94165 2.21641 1.11776 1.20284C0.836044 1.68879 0.67406 2.25222 0.67406 2.85227C0.673745 3.39245 0.806769 3.92435 1.06133 4.40079C1.31589 4.87723 1.68411 5.28347 2.13333 5.58347C1.61236 5.56689 1.10288 5.42612 0.647297 5.17287V5.21513C0.647244 5.97276 0.909313 6.70707 1.38903 7.29346C1.86876 7.87986 2.53658 8.28223 3.2792 8.43229C2.79591 8.56309 2.28922 8.58235 1.79739 8.48863C2.00691 9.14053 2.41504 9.71058 2.96464 10.119C3.51424 10.5274 4.1778 10.7537 4.86242 10.7663C3.70024 11.6786 2.26495 12.1735 0.787449 12.1713C0.525725 12.1714 0.264221 12.1561 0.00428772 12.1255C1.50404 13.0898 3.24986 13.6016 5.03286 13.5996C11.0686 13.5996 14.3681 8.60061 14.3681 4.26506C14.3681 4.1242 14.3646 3.98193 14.3583 3.84108C15.0001 3.37694 15.5541 2.80219 15.9943 2.14376L15.9957 2.14165Z"
                        fill="white"
                      />
                    </svg>
                  </S.Social>
                </Link>
                <Link
                  href="https://feeds.feedburner.com/centraldagrama"
                  passHref
                >
                  <S.Social
                    className="feeds"
                    target="_blank"
                    rel="noreferrer"
                    title="Inscreva-se em nosso Feed de Notícias!"
                  >
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.14611 15.5265C5.14611 16.9476 3.99411 18.0996 2.57303 18.0996C1.15196 18.0996 0 16.9476 0 15.5265C0 14.1054 1.152 12.9534 2.57307 12.9534C3.99415 12.9534 5.14611 14.1055 5.14611 15.5265ZM12.2039 17.4249C11.8682 11.2133 6.89287 6.23174 0.674638 5.89573C0.307607 5.87588 0 6.17079 0 6.53834V8.46968C0 8.80778 0.260759 9.09132 0.598138 9.11326C5.09138 9.40592 8.69303 12.9986 8.98629 17.5014C9.00827 17.8388 9.29181 18.0995 9.62987 18.0995H11.5612C11.9288 18.0996 12.2237 17.792 12.2039 17.4249ZM17.9996 17.4365C17.6623 8.04198 10.1035 0.438932 0.663066 0.100027C0.300254 0.087009 0 0.380111 0 0.743123V2.67442C0 3.02096 0.27462 3.30301 0.620879 3.31724C8.30217 3.63219 14.4675 9.79866 14.7824 17.4787C14.7966 17.825 15.0786 18.0996 15.4252 18.0996H17.3565C17.7195 18.0996 18.0126 17.7993 17.9996 17.4365Z"
                        fill="white"
                      />
                    </svg>
                  </S.Social>
                </Link>
              </S.Socials>
            </S.Top>
          </S.Content>
          <hr />
          <S.Content>
            <h4>
              Grama de qualidade <span>Direto do Produtor,</span> compre com
              melhor preço por m2!
            </h4>
            <Link href="/comprar-grama" passHref title="Faça seu orçamento">
              <S.Button
                shape="rounded"
                as="a"
                title="cotar preço de grama"
                aria-label="faça seu orçamento"
              >
                faça seu orçamento <ArrowForwardIcon w={4} h={4} />
              </S.Button>
            </Link>
            <S.Copyright>
              ©{anoAtual} - Desenvolvido com ❤️ by{' '}
              <Link href="https://www.versatilnow.com/" passHref>
                <a
                  target="_blank"
                  rel="noreferrer"
                  title="Versatilnow"
                  aria-label="abrir link versatilnow"
                >
                  Versatilnow
                </a>
              </Link>
            </S.Copyright>
          </S.Content>
        </S.Left>
        <S.Right>
          <S.Menu>
            <S.Ul>
              <li>
                <S.Label>Links úteis</S.Label>
              </li>

              <li>
                <Link href="/mapa-do-site" passHref>
                  <S.MenuLink title="mapa central da grama">
                    Mapa do site
                  </S.MenuLink>
                </Link>
              </li>
            </S.Ul>
            <S.Ul>
              <li>
                <S.Label>Páginas</S.Label>
              </li>
              <li>
                <Link href="/" passHref>
                  <S.MenuLink title="página inicial central da grama">
                    Início
                  </S.MenuLink>
                </Link>
              </li>
              <li>
                <Link href="/dicas" passHref>
                  <S.MenuLink title="dicas sobre grama">Dicas</S.MenuLink>
                </Link>
              </li>
              <li>
                <Link href="/fotos" passHref>
                  <S.MenuLink title="fotos de grama">Fotos</S.MenuLink>
                </Link>
              </li>
              <li>
                <Link href="/gramas" passHref>
                  <S.MenuLink title="tipos de gramas">Gramas</S.MenuLink>
                </Link>
              </li>
              <li>
                <Link href="/comprar-grama" passHref>
                  <S.MenuLink title="comprar grama preço">Orçamento</S.MenuLink>
                </Link>
              </li>
              <li>
                <Link href="/contato" passHref>
                  <S.MenuLink title="orçar grama">Contato</S.MenuLink>
                </Link>
              </li>
            </S.Ul>
          </S.Menu>
          <S.Phones>
            <S.Phone>
              <svg
                width="11"
                height="17"
                viewBox="0 0 11 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.45459 0.5H2.63641C1.42914 0.5 0.45459 1.47455 0.45459 2.68182V14.3182C0.45459 15.5255 1.42914 16.5 2.63641 16.5H8.45459C9.66186 16.5 10.6364 15.5255 10.6364 14.3182V2.68182C10.6364 1.47455 9.66186 0.5 8.45459 0.5ZM9.18186 12.8636H1.90914V2.68182H9.18186V12.8636ZM7.00004 15.0455H4.09095V14.3182H7.00004V15.0455Z"
                  fill="#111827"
                />
              </svg>

              <S.PhoneText>(15) 3373-1271</S.PhoneText>
            </S.Phone>
            <S.Phone>
              <svg
                width="11"
                height="17"
                viewBox="0 0 11 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.45459 0.5H2.63641C1.42914 0.5 0.45459 1.47455 0.45459 2.68182V14.3182C0.45459 15.5255 1.42914 16.5 2.63641 16.5H8.45459C9.66186 16.5 10.6364 15.5255 10.6364 14.3182V2.68182C10.6364 1.47455 9.66186 0.5 8.45459 0.5ZM9.18186 12.8636H1.90914V2.68182H9.18186V12.8636ZM7.00004 15.0455H4.09095V14.3182H7.00004V15.0455Z"
                  fill="#111827"
                />
              </svg>

              <S.PhoneText>(11) 9 9617-2172</S.PhoneText>
            </S.Phone>
            <S.Phone>
              <svg
                width="11"
                height="17"
                viewBox="0 0 11 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.45459 0.5H2.63641C1.42914 0.5 0.45459 1.47455 0.45459 2.68182V14.3182C0.45459 15.5255 1.42914 16.5 2.63641 16.5H8.45459C9.66186 16.5 10.6364 15.5255 10.6364 14.3182V2.68182C10.6364 1.47455 9.66186 0.5 8.45459 0.5ZM9.18186 12.8636H1.90914V2.68182H9.18186V12.8636ZM7.00004 15.0455H4.09095V14.3182H7.00004V15.0455Z"
                  fill="#111827"
                />
              </svg>

              <S.PhoneText>(11) 9 4834-2269</S.PhoneText>
            </S.Phone>
          </S.Phones>
        </S.Right>
      </SimpleGrid>
    </S.Footer>
  )
}

export default Footer
