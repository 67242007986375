import { styled } from 'styles/stitches.config'
import Image from 'next/image'
import { rem } from 'polished'

import ButtonComponent from 'components/Button'

export const Footer = styled('footer', {
  '& hr': {
    my: 25,
    width: '100%',

    borderColor: '$emerald500',

    '@md': {
      maxWidth: 600,
    },
  },

  '& h4': {
    '@md': {
      maxWidth: 340,
    },

    '& span': {
      color: '$emerald500',
    },
  },
})

export const Left = styled('div', {
  paddingTop: 50,
  paddingBottom: 50,
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',

  backgroundColor: '$emerald50',

  '@lg': {
    paddingTop: 112,
    paddingBottom: 112,
    borderRadius: '0 128px 0 0',
  },
})

export const Content = styled('div', {
  width: '100%',
  paddingLeft: 16,
  paddingRight: 16,
  textAlign: 'center',

  '@md': {
    textAlign: 'left',
    maxWidth: 600,
  },

  '@lg': {
    paddingRight: 50,
  },
})

export const Top = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
})

export const LogoWrap = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

export const Logo = styled(Image, {})

export const Socials = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

export const Social = styled('a', {
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  borderRadius: '100%',

  '&.instagram': {
    backgroundColor: '#962FBF',
  },
  '&.google': {
    backgroundColor: '#BB001B',
  },
  '&.facebook': {
    backgroundColor: '#2F80ED',
  },
  '&.twitter': {
    backgroundColor: '#56CCF2',
  },
  '&.feeds': {
    backgroundColor: '#F26522',
  },

  '&:not(:last-of-type)': {
    marginRight: 10,
  },

  '&:hover': {
    opacity: 0.8,
  },
})

export const Button = styled(ButtonComponent, {
  display: 'inline-flex',
  alignItems: 'center',
  margin: '40px auto',

  '& svg': {
    marginLeft: 5,
    marginTop: '-2px',
  },
})

export const LogoText = styled('p', {
  fontFamily: '$domine',
  color: '$gray900',
  lineHeight: '120%',

  '@md': {
    fontSize: rem(20),
  },
})

export const Copyright = styled('p', {
  fontFamily: '$raleway',
  fontSize: rem(13),
  color: '$grey600',

  '& a': {
    color: '$emerald900',
    fontWeight: 'bold',
  },
})

export const Right = styled('div', {
  padding: 16,

  '@md': {
    paddingTop: 112,
  },

  '@lg': {
    paddingLeft: 90,
    maxWidth: 700,
  },
})

export const Menu = styled('nav', {
  display: 'flex',
  paddingInline: 40,
  justifyContent: 'space-between',

  '@lg': {
    paddingInline: 0,
    justifyContent: 'flex-start',
  },
})

export const Ul = styled('ul', {
  listStyle: 'none',
  '@lg': {
    '&:not(:last-of-type)': {
      marginRight: 160,
    },
  },
})

export const Label = styled('p', {
  fontFamily: '$domine',
  fontWeight: 'bold',
  fontSize: rem(16),
  color: '$grey900',

  '@lg': {
    marginBottom: 24,
  },
})

export const MenuLink = styled('a', {
  display: 'block',

  fontFamily: '$raleway',
  fontSize: rem(13),
  color: '$grey600',
  lineHeight: '180%',
  cursor: 'pointer',

  '&:not(:last-of-type)': {
    marginBottom: 8,
  },

  '&:hover': {
    color: '$black',
  },
})

export const Phones = styled('div', {
  display: 'flex',
  marginTop: 56,
  justifyContent: 'space-between',

  '@lg': {
    justifyContent: 'flex-start',
  },
})

export const Phone = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '@lg': {
    flexDirection: 'row',
    alignItems: 'center',
    '&:not(:last-of-type)': {
      marginRight: 20,
    },
  },
})

export const PhoneText = styled('p', {
  marginLeft: 10,

  fontFamily: '$domine',
  color: '$emerald500',
  fontSize: rem(12),
  marginTop: 5,

  '@md': {
    fontSize: rem(16),
  },

  '@lg': {
    marginTop: 0,
  },
})
