import {
  Container as ContainerDefault,
  ContainerProps,
} from "@chakra-ui/react";

const Container = ({ ...props }: ContainerProps) => {
  return (
    <ContainerDefault {...props} maxW="7xl">
      {props.children}
    </ContainerDefault>
  );
};

export default Container;
