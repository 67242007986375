import { styled } from 'styles/stitches.config'
import { rem } from 'polished'

export const FloatButton = styled('div', {
  position: "fixed",
  "z-index": "1000",
  "bottom": rem(20),
  "right": rem(20),
  "display": "flex",
  "flexDirection": "column",
  "gap": rem(20),

  '@lg': {
    "bottom": rem(40),
    "right": rem(40),
  }
})

export const Link = styled('a', {
  "display": "flex",
  "width": rem(50),
  "height": rem(50),
  "border-radius": rem(50),
  "alignItems": "center",
  "justifyContent": "center",
  "cursor": "pointer",

  'svg': {
    width: rem(15),
    color: "#fff"
  },

  variants: {
    color: {
      green: { backgroundColor: '$emerald400' },
      gray: { backgroundColor: '$grey400' },
    },
  },
  '@lg': {
    "width": rem(60),
    "height": rem(60),
    'svg': {
      width: rem(25),
    },
  }
})
