import { styled } from 'styles/stitches.config'
import { rem } from 'polished'
import Image from 'next/image'
import { IconButton } from '@chakra-ui/react'

import ButtonComponent from 'components/Button'

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 10,
  position: 'relative',

  borderRadius: 32,
  backgroundColor: 'white',
  transition: 'border-radius 0.2s',

  variants: {
    open: {
      true: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
})

export const LogoWrap = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

export const LogoContent = styled('span', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    color: '$emerald500',
  },
})

export const LogoText = styled('p', {
  fontFamily: '$domine',
  color: '$gray900',
  lineHeight: '120%',

  '@md': {
    fontSize: rem(20),
  },
})

export const LogoImage = styled(Image, {})

export const Navbar = styled('nav', {
  display: 'none',

  variants: {
    open: {
      true: {
        '@maxmd': {
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          background: 'white',
          right: '0',
          top: '65px',
          zIndex: '99',
          width: '100%',
          padding: '20px',
          borderBottomRightRadius: '10px',
          borderBottomLeftRadius: '10px',
        },
      },
    },
  },

  '@md': {
    display: 'flex',
  },
})

export const Navbar_Link = styled('a', {
  fontFamily: '$raleway',
  fontSize: rem(14),
  lineHeight: '180%',
  color: '$gray600',
  cursor: 'pointer',
  
  '@maxmd': {
    padding: '10px 0',
  },

  '&:hover': {
    color: '$emerald500',
  },

  '&:not(:last-of-type)': {
    marginRight: 32,
  },
})

export const Button = styled(ButtonComponent, {
  display: 'none',

  '& svg': {
    marginRight: 10,
  },

  '@md': {
    display: 'flex',
    alignItems: 'center',

    '& span': {
      marginRight: '5px !important',
    },
  },
})

export const ButtonIcon = styled(Image, {
  marginRight: 10,
})

export const ButtonMenu = styled(IconButton, {
  marginRight: 19,
  borderRadius: '32px !important',

  '@md': {
    display: 'none !important',
  },
})
